"use strict";
import { createStore } from "vuex";

/*
export const store = createStore({
  state () {
    return {
      count: 1
    }
  }
})
*/

function realEncodeURI(w) {
  let url = encodeURI(w);
  url = url.replace("+", "%2B");
  return url;
}

function mapKoralParams(koralQuery, type) {
  if (type == "collection") {
    return koralQuery.collection.operands.map(
      // korpused[0]=dialoogid
      // korpused[1]=monoloogid
      (operand, i) => {
        let value;
        if (operand.value === "skk0") {
          value = "dialoogid";
        } else if (operand.value === "skk1") {
          value = "monoloogid";
        } else if (operand.value === "skk2") {
          value = "valitood";
        } else if (operand.value === "skk3") {
          value = "trialoogid";
        }
        return "korpused[" + i + "]=" + value;
      }
    );
  }
  if (type == "query") {
    return koralQuery.query.operands.map(
      // select[0][layer]=word
      // select[0][logic]=REGEXP
      // select[0][text]=õ.*
      (operand, i) => {
        let layer = "select[" + i + "][layer]=" + operand.layer;
        let logic = "select[" + i + "][logic]=REGEXP";
        let text;
        if (operand.match === "startswith") {
          text = "select[" + i + "][text]=" + operand.key + ".*";
        } else if (operand.match === "endswith") {
          text = "select[" + i + "][text]=" + ".*" + operand.key;
        } else if (operand.match === "contains") {
          text = "select[" + i + "][text]=" + ".*" + operand.key + ".*";
        } else if (operand.match === "isnot") {
          logic = "select[" + i + "][logic]=NOT REGEXP";
          text = "select[" + i + "][text]=" + operand.key;
        } else {
          // default to simple 'is-equal-to' operator
          text = "select[" + i + "][text]=" + operand.key;
          console.log(
            "VIGA: tundmatu operaator '" +
              operand.match +
              "' funktsioonis 'getOldApiSearchUrl'"
          );
        }
        return [layer, logic, text].join("&");
      }
    );
  }
}

export const store = createStore({
  state() {
    return {
      _nextFilterId: 3,
      isFetchingResults: false,
      isFetchingErrors: false,
      fetchCompleted: false,
      filters: [
        {
          id: 1,
          left: "word",
          operator: "startswith",
          right: "sõ",
        },
      ],
      checkedCorpora: ["skk0", "skk1", "skk2", "skk3"],
      limit: 10,
      resultsList: { result: { results: [] } },
      textgridDisplayWidth: 800,
      textgridDisplayWidthNormal: 800,
      lastQueryCoralUrl: null, //variable for storing most recent succesful search URL
    };
  },
  mutations: {
    // Set bigger display width for TextGrid items
    displayTextGridsMoreWide(state) {
      state.textgridDisplayWidth += 100;
    },

    setLastQueryCoralUrl(state, url) {
      state.lastQueryCoralUrl = url;
    },

    // Set smaller display width for TextGrid items
    displayTextGridsMoreNarrow(state) {
      state.textgridDisplayWidth -= 100;
    },

    // Re-set normal display width for TextGrid items
    displayTextGridsNormal(state) {
      state.textgridDisplayWidth = state.textgridDisplayWidthNormal;
    },

    // adds a new filter to the end of the filters list
    addFilter(state, newFilter) {
      newFilter.id = state._nextFilterId++;
      state.filters.push(newFilter);
    },

    // Removes a filter from the filters list
    removeFilter(state, idToRemove) {
      state.filters = state.filters.filter((filter) => {
        return filter.id !== idToRemove;
      });
    },

    // Set a new limit
    setLimit(state, newLimit) {
      state.limit = newLimit;
    },

    // Set which corporas are searched
    setCheckedCorpora(state, newCheckings) {
      state.checkedCorpora = newCheckings.sort();
    },

    // Set the results list
    setResultsList(state, newResultsList) {
      state.resultsList = newResultsList;
    },

    // Un-sets the loding state
    setFetchingResults(state, isFetching) {
      state.isFetchingErrors = false; // TODO state.commit('clearErrored');
      state.isFetchingResults = isFetching === true;
    },

    // Un-sets the loding state
    isFetchCompleted(state, completed) {
      state.fetchCompleted = completed;
    },

    // Sets error message
    setErrorMessage(state, message) {
      state.ErrorMessage = message;
    },

    // Sets errored state
    setErrored(state) {
      state.isFetchingErrors = true;
    },

    // Clears an errored state
    clearErrored(state) {
      state.isFetchingErrors = false;
    },

    setResponseFailed(state) {
      state.responseSuccessful = false;
    },

    resetResponseState(state) {
      state.responseSuccessful = true;
    },

    // Set state according to Koral Query object
    setStateFromKoralQuery(state, koralquery) {
      // console.log("state.limit before ", state.limit, " after ", koralquery.meta.count);
      state.limit = koralquery.meta.count;
      state.checkedCorpora = koralquery.collection.operands.map(function (
        operand
      ) {
        return operand.value;
      });
      state.filters = koralquery.query.operands.map(function (operand) {
        return {
          id: state._nextFilterId++,
          left: operand.layer,
          operator: operand.match,
          right: operand.key,
        };
      });
    },

    // Clears the results list (done while fetching new results)
    clearResultsList(state) {
      state.resultsList = { result: { results: [] } };
    },
  },

  getters: {
    // Gets the display width for TextGrid items
    getTextGridDisplayWidth(state) {
      // console.log("display width:", state.textgridDisplayWidth);
      return state.textgridDisplayWidth;
    },
    getLastQueryCoralUrl(state) {
      return state.lastQueryCoralUrl;
    },

    // Get a KoralQuery object for the current search
    getCurrentSearchAsKoralQuery(state) {
      return {
        "@context": "http://korap.ids-mannheim.de/ns/koral/0.5/context.jsonld",
        collection: {
          "@type": "koral:docGroup",
          operation: "operation:or",
          operands: state.checkedCorpora.map(function (corpusName) {
            return {
              "@type": "koral:doc",
              key: "title",
              match: "match:eq",
              value: corpusName,
              type: "type:string",
            };
          }),
        },
        query: {
          "@type": "koral:termGroup",
          operation: "operation:and",
          operands: state.filters.map(function (filter) {
            return {
              "@type": "koral:term",
              layer: filter.left,
              match: filter.operator,
              key: filter.right,
              flags: ["flags:caseInsensitive"],
            };
          }),
        },
        meta: { count: Number.parseInt(state.limit) },
      };
    },

    // builds an url for the old server
    getOldApiSearchUrl(state, getters) {
      let koralQuery = getters.getCurrentSearchAsKoralQuery;
      let baseUrl = process.env.VUE_APP_API_ROOT + "search/";
      let selects = mapKoralParams(koralQuery, "query");
      let corpora = mapKoralParams(koralQuery, "collection");
      let limit = "limit=" + koralQuery.meta.count.toString();
      let submit = "submit=Otsi";
      return (
        baseUrl +
        "?" +
        [selects.join("&"), corpora.join("&"), limit, submit].join("&")
      );
    },

    // makes archive url from last successful search url
    getOldApiZipUrl(state, getters) {
      return getters.getLastQueryCoralUrl.replace("/search/", "/zip/");
    },

    // Get the current results list
    getResultsList(state) {
      return state.resultsList;
    },
  },

  actions: {
    // Saves the state as an historical state in the browser with url
    saveHistoricalState(context) {
      let historicalState = {
        koralQuery: context.getters.getCurrentSearchAsKoralQuery,
        resultsList: JSON.stringify(context.getters.getResultsList),
      };
      let stateTitle = "";
      let stateUrl = "?" + JSON.stringify(historicalState.koralQuery);

      history.pushState(historicalState, stateTitle, stateUrl);
    },

    // download recent search result as Zip
    fetchZip(context) {
      let zipUrl = context.getters.getOldApiZipUrl;
      let filename = "tulemus.zip"; // default filename will be overwritten by filename from headers
      let url = realEncodeURI(zipUrl);

      const form = document.getElementById("result_form");
      const formattedFormData = new FormData(form);

      if (!formattedFormData.get("download[]")) {
        alert("Pole valitud ühtegi rida allalaadimiseks.");
        return;
      }

      //formattedFormData.append('download', [1,2]);

      fetch(url, {
        method: "POST",
        body: formattedFormData,
      })
        .then((result) => {
          if (!result.ok) {
            throw Error(result.statusText);
          }
          // We are reading the *Content-Disposition* header for getting the original filename given from the server
          const header = result.headers.get("Content-Disposition");
          if (header && header.length) {
            const parts = header.split(";");
            filename = parts[1].split("=")[1].replaceAll('"', "");
          }

          return result.blob();
        })
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          //console.log('url', url);
          const link = document.createElement("a");
          link.href = url;
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },

    // fetch results from the API
    // TODO as this handles the whole search, this should be renamed searchHandler
    fetchResults(context) {
      // code taken from https://stackoverflow.com/a/57888548
      let url = context.getters.getOldApiSearchUrl;
      let urlEscaped = realEncodeURI(url);
      const ms = 30000; // timeout value
      context.commit("clearResultsList"); // TODO should this be done here on in App?
      context.commit("isFetchCompleted", false);
      context.commit("setFetchingResults", true);
      context.commit("resetResponseState");
      context.commit("clearErrored");
      const controller = new AbortController();
      const options = {
        signal: controller.signal,
        method: "GET",
        headers: new Headers(),
        mode: "cors",
        cache: "default",
      };
      const promise = window.fetch(urlEscaped, options);
      const timeout = setTimeout(() => {
        controller.abort();
      }, ms);

      return promise
        .finally(() => clearTimeout(timeout))
        .then((response) => {
          let respJSON = response.json();
          return respJSON;
        })
        .then((data) => {
          context.commit("setFetchingResults", false);
          context.commit("setResultsList", data);
          context.commit("setLastQueryCoralUrl", url);
        })
        .catch((error) => {
          context.commit("setErrored");
          context.commit("setFetchingResults", false);
          console.log("fetchResults error:", error);
          if (error.name == "AbortError") {
            context.commit(
              "setErrorMessage",
              "Server ei jõudnud vastata, proovi uuesti."
            );
          } else {
            context.commit("setErrorMessage", "Vigane päring.");
          }
          // else {
          //  alert("Server ei tundu vastavat, proovi uuesti või teavita veebihaldajat.");
          // }
        })
        .finally(() => {
          // save the search and results to history
          context.commit("isFetchCompleted", true);
          context.dispatch("saveHistoricalState");
        });
    },
  },

  modules: {},
});
